import React from "react";
import _ from "lodash";

import DateRange from "../components/DateRange";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix, getAccessLevel } from "../utils";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default class Education extends React.Component {
  render() {
    let level = getAccessLevel('level');

    return (
      <Layout {...this.props}>
        <article className="project project-full">
          <header className="project-header">
            <h1 className="project-title underline">
              {_.get(this.props, "pageContext.frontmatter.institution")}
            </h1>
          </header>
          {_.get(this.props, "pageContext.frontmatter.department") && (
            <div className="project-subtitle">
              {htmlToReact(
                _.get(this.props, "pageContext.frontmatter.department")
              )}
            </div>
          )}
          <div className="project-upper">
            <div className="project-content">
              <p>
                {level > 1 &&
                  _.get(this.props, "pageContext.frontmatter.excerpt")}
              </p>

              {_.get(this.props, "pageContext.frontmatter.degree") && (
                <cite>
                  {"Abschluss: " +
                    htmlToReact(
                      _.get(this.props, "pageContext.frontmatter.degree")
                    )}
                </cite>
              )}
              {_.get(this.props, "pageContext.frontmatter.education_url") && (
                <p>
                  <br />
                  <OutboundLink
                    href={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.education_url")
                    )}
                    target="_blank"
                    rel="noopener"
                  >
                    {_.get(this.props, "pageContext.frontmatter.institution")}
                  </OutboundLink>
                </p>
              )}
            </div>
            {_.get(this.props, "pageContext.frontmatter.content_img_path") && (
              <div className="project-thumbnail">
                <img
                  src={safePrefix(
                    _.get(
                      this.props,
                      "pageContext.frontmatter.content_img_path"
                    ).replace(/\.png|\.jpg$/, ".webp")
                  )}
                  alt={_.get(this.props, "pageContext.frontmatter.institution")}
                />
              </div>
            )}
          </div>
          <div className="project-content">
            {htmlToReact(_.get(this.props, "pageContext.html"))}
          </div>
          <footer className="project-meta">
            <DateRange {...this.props.pageContext}>Besucht: </DateRange>
          </footer>
        </article>
      </Layout>
    );
  }
}
